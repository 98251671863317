import React, { useState, useContext, useEffect } from "react"
import MainContext from "../../contexts/MainContext"
import { LoginContext } from "../../contexts/loginContext"
import { CommContext } from "../../contexts/commContext"
import config from "../../config"

import cloneDeep from "lodash.clonedeep"
import NumberFormat from "react-number-format"
import moment from "moment"
import { toast } from "react-toastify"

import LoadTranskey from "../../helpers/LoadTranskey"
import LoadAssets from "../../helpers/LoadAssets"
import GetLayout from "../../helpers/GetLayout"
import TempBooking from "../../helpers/TempBooking"

import CountButton from "../../components/CountButton"

export default function SunbedResSelectItems(props) {
  const { loggedIn } = useContext(LoginContext)
  const { setPinModalVisible } = useContext(MainContext)
  const { ioClient, ioConnected } = useContext(CommContext)

  const [layout, setLayout] = useState(null)
  const [resItems, setResItems] = useState({
    entitiesAllData: {},
    totalPrice: 0,
    entities: {},
  })

  useEffect(() => {
    if (ioClient != null && ioConnected) {
      ioClient.on("entityAvailabilityUpdated", onEntityAvailabilityUpdated)
    }

    return () => {
      if (ioClient != null)
        ioClient.off("entityAvailabilityUpdated", onEntityAvailabilityUpdated)
    }
    // eslint-disable-next-line
  }, [ioClient, ioConnected])

  useEffect(() => {
    async function getLayout() {
      try {
        let resp = await GetLayout(config.layoutID, props.date)
        if (resp.success) setLayout(resp.data)
      } catch (e) {
        toast.error(LoadTranskey("error"))
        console.log(e)
      }
    }

    if (props.date != null) getLayout()
  }, [props.date])

  useEffect(() => {
    if (localStorage["costes-res-reserve-items"] != null) {
      try {
        setResItems(JSON.parse(localStorage["costes-res-reserve-items"]))
      } catch (e) {
        console.log(e)
      }
    }
    // eslint-disable-next-line
  }, [localStorage["costes-res-reserve-items"]])

  const onEntityAvailabilityUpdated = async (data) => {
    console.log("update-select-items", data)

    try {
      let resp = await GetLayout(config.layoutID, props.date)
      if (resp.success) setLayout(resp.data)
    } catch (e) {
      toast.error(LoadTranskey("error"))
      console.log(e)
    }
  }

  const handleChangeItemCount = async (entity, params) => {
    if (loggedIn) {
      let newValues = cloneDeep(resItems)
      let minValue = entity.entity.seatsPerBookingMin
      let seats = params.count
      let entityPrice = getEntityData(entity)["seatPrice"]

      if (minValue != null && seats < minValue)
        seats = params.diff < 0 ? 0 : minValue

      if (seats === 0) {
        delete newValues.entitiesAllData[entity.entity.id]
        console.log(newValues)
      }

      if (seats !== 0) {
        newValues.entitiesAllData = Object.assign(newValues.entitiesAllData, {
          [entity.entity.id]: { seats, entityPrice, entity },
        })
      }

      newValues.totalPrice = getTotalPrice(newValues.entitiesAllData)

      if (newValues.entities == null) newValues.entities = {}

      for (let row of Object.values(newValues.entitiesAllData)) {
        newValues.entities["entity_" + row.entity.entity.id] = {
          id: parseInt(row.entity.entity.id),
          seats: row.seats,
        }
      }

      let isToday = moment(props.date).isSame(moment(), "day")
      let date = moment()
      let beforeClose = true
      let afterOpen = true

      if (isToday) {
        let entityOpenTime = getEntityOpenTime(entity).openTime
        let entityOpenDate = props.date + " " + entityOpenTime

        let nowBeforeOpen = moment().isBefore(entityOpenDate)

        if (nowBeforeOpen) {
          afterOpen = false
        }

        let entityCloseTime = getEntityOpenTime(entity).closeTime
        let entityCloseDate = props.date + " " + entityCloseTime

        let nowAfterClose = moment().isAfter(entityCloseDate)

        if (nowAfterClose) {
          beforeClose = false
          toast.error(LoadTranskey("afterClose"))
        }

        console.log(nowBeforeOpen)
      }

      if (!isToday) {
        let hour = parseInt(Object.keys(entity.availability)[0])
        if (hour < 10) hour = "0" + hour

        date = moment(props.date + " " + hour + ":00")
      }

      newValues.bookDate = date

      let resp = { success: true }

      if (beforeClose && afterOpen && seats > 0) {
        try {
          let bookingParams = {
            entityID: entity.entity.id,
            bookDate: newValues.bookDate,
            bookDuration: 60,
            seats,
          }

          resp = await TempBooking(bookingParams)
        } catch (e) {
          toast.error(LoadTranskey("error"))
          console.log(e)
        }
      }

      if (!resp.success && resp.code === 401) {
        setPinModalVisible(true)
      }

      if (beforeClose && resp.success) {
        localStorage["costes-res-reserve-items"] = JSON.stringify(newValues)
        setResItems(newValues)
      } else {
        toast.error(LoadTranskey("error"))
      }
    }
  }

  const checkUserLoggedIn = () => {
    return loggedIn
  }

  const handleClickOnCountButton = () => {
    if (!loggedIn) {
      setPinModalVisible(true)
    }
  }

  const addItem = (entity) => {
    if (loggedIn) handleChangeItemCount(entity, { count: 1, diff: 1 })

    if (!loggedIn) setPinModalVisible(true)
  }

  const getEntityData = (entity) => {
    let isToday = moment(props.date).isSame(moment(), "day")

    let availableSeats = 0
    let seatPrice = 0
    let seatsPerBookingMax = 0

    try {
      if (isToday) {
        let now = moment()
        let hour = now.hours()
        let min = now.minutes()
        min = min - (min % 15)
        if (min === 0) min = "00"

        availableSeats =
          entity.availability[hour]?.segments[min]?.availableSeats
        seatPrice = entity.availability[hour]?.segments[min]?.seatPrice

        if (availableSeats == null) {
          hour = Object.keys(entity.availability)[0]
          availableSeats =
            entity.availability[hour]?.segments["00"]?.availableSeats
        }
      }
      if (!isToday) {
        availableSeats = Object.values(entity.availability)[0]?.segments["00"]
          ?.availableSeats
        seatPrice = Object.values(entity.availability)[0]?.segments["00"]
          ?.seatPrice
      }

      if (availableSeats == null)
        availableSeats = entity.entity.seatsPerBookingMax
      if (seatPrice == null) seatPrice = entity.entity.seatPrice

      seatsPerBookingMax =
        availableSeats > entity.entity.seatsPerBookingMax
          ? entity.entity.seatsPerBookingMax
          : availableSeats
    } catch (e) {
      console.log(e)
    }

    return {
      availableSeats,
      seatsPerBookingMax,
      seatPrice: parseInt(seatPrice),
    }
  }

  const getEntityOpenTime = (entity) => {
    let openHour = Object.keys(entity.availability)[0]
    let openTime = (openHour < 10 ? "0" + openHour : openHour) + ":00"
    let closeTime = Object.keys(entity.availability).slice(-1)[0] + ":00"

    return { openTime, closeTime }
  }

  const getTotalPrice = (items) => {
    if (items == null) return 0
    return Object.keys(items).reduce(
      (sum, key) =>
        sum + parseFloat(items[key].entityPrice * items[key].seats || 0),
      0
    )
  }

  if (layout == null) return null

  let totalPrice = getTotalPrice(resItems.entitiesAllData)

  return (
    <div className="select-items-container flex-grow flex flex-col">
      <div className="items-images-container select-none flex justify-between mb-1 relative lg:h-48">
        {Object.values(layout).map((entity, index) => {
          let imageContainerClass = "first"

          if (index === 1) imageContainerClass = "second"
          if (index === 2) imageContainerClass = "third"
          if (index === 3) imageContainerClass = "fourth"
          if (index === 4) imageContainerClass = "fifth"

          let availableTime = getEntityOpenTime(entity)

          return (
            <div
              key={index}
              className={`image-container ${imageContainerClass} w-24 h-24 rounded-full lg:w-48 lg:h-48 lg:absolute`}
            >
              <LoadAssets
                imgSrc={`${process.env.PUBLIC_URL}/assets/img/${imageContainerClass}.jpg`}
                imgSrcXs={`${process.env.PUBLIC_URL}/assets/img/${imageContainerClass}.jpg`}
                addClass={
                  "w-full h-full object-cover rounded-full lg:border-white lg:border-4"
                }
              />
              <div className="item-image-label select-none w-26 h-12 pt-1 flex flex-col justify-between text-center text-blue mx-auto">
                <div className="item-label-name flex-grow flex items-center justify-center text-sm leading-none">
                  {entity.entity.name[localStorage["selectedLanguage"]]}
                </div>
                <div className="item-available text-xs">
                  {`(${availableTime.openTime}-${availableTime.closeTime})`}
                </div>
                <div className="item-available text-xs">
                  <span className="item-available-text">
                    {LoadTranskey("available")}:
                  </span>
                  <span>{getEntityData(entity)["availableSeats"]}</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="items-count-container flex-grow text-blue mt-20">
        {Object.values(layout).map((entity, index) => {
          return (
            <div
              key={index}
              className="item-container flex justify-between items-center text-xs py-1 border-b border-blue-light border-opacity-60"
            >
              <div className="item-label select-none w-2/4 pl-2">
                <div className="item-name">
                  {entity.entity.name[localStorage["selectedLanguage"]]}
                </div>
                <div className="item-unit">
                  (
                  <span>
                    <NumberFormat
                      value={getEntityData(entity)["seatPrice"]}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      suffix={` Ft`}
                    />
                  </span>
                  /
                  <span>
                    {LoadTranskey(entity.entity.id === 72 ? "person" : "piece")}
                  </span>
                  /<span>{LoadTranskey("day")}</span>)
                </div>
              </div>
              <div
                className="count-button w-1/4"
                onClick={handleClickOnCountButton}
              >
                <CountButton
                  onChange={(e) => {
                    handleChangeItemCount(entity, e)
                  }}
                  count={
                    resItems.entitiesAllData[entity.entity.id]?.seats == null
                      ? 0
                      : resItems.entitiesAllData[entity.entity.id].seats
                  }
                  addItem={() => {
                    addItem(entity)
                  }}
                  canSelect={checkUserLoggedIn}
                  maxValue={getEntityData(entity)["seatsPerBookingMax"]}
                  firstStep={entity.entity.seatsPerBookingMin}
                />
              </div>
              <div className="item-sum-price w-1/4 text-right pr-2 select-none">
                <NumberFormat
                  value={
                    resItems.entitiesAllData[entity.entity.id]?.seats == null
                      ? 0
                      : resItems.entitiesAllData[entity.entity.id].seats *
                        getEntityData(entity)["seatPrice"]
                  }
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  suffix={` Ft`}
                />
              </div>
            </div>
          )
        })}
        <div className="item-container flex justify-between items-center text-xs py-1">
          <div className="item-label select-none w-2/4 pl-2">
            <div className="item-name">{LoadTranskey("total")}</div>
          </div>
          <div className="count-button w-1/4"></div>
          <div className="item-sum-price w-1/4 text-right pr-2 select-none">
            <NumberFormat
              value={totalPrice}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              suffix={` Ft`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
