import packageJson from "../package.json"

const prod = true

/** Dev config **/
let config = {
  prod: false,
  version: packageJson.version,
  endpoint: "https://bookingdev.okeoke.io",
  apiBaseUrl: "https://bookingdev.okeoke.io/",
  backRef: "https://costesdev.okeoke.io/bookingview/viva",
  basename: "/",
  layoutID: 1,
}

/** Prod config  **/
if (prod) {
  config = {
    prod: true,
    version: packageJson.version,
    endpoint: "https://booking.okeoke.io",
    apiBaseUrl: "https://booking.okeoke.io/",
    backRef: "https://book.costesbeachclub.hu/bookingview/viva",
    basename: "/",
    layoutID: 1,
  }
}

export default config
